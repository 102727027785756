import React from 'react';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { connect } from 'react-redux';

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
    this.state = this.initState();
  }

  initState = () => ({
    open: true,
  });

  componentDidMount = () => {
    M.Collapsible.init(document.querySelectorAll('.collapsible'), {
      accordion: false,
    });
    M.Sidenav.init(document.querySelector('#side-navigation'), {
      draggable: true,
      preventScrolling: false,
      onOpenStart: this.setActiveLink,
    });
  };

  setActiveLink = () => {
    const links = document.querySelectorAll('.sidenav-close');
    links.forEach((el) => {
      if (el.getAttribute('href') === window.location.pathname)
        el.classList.add('active');
      else el.classList.remove('active');
    });
  };

  openSideNav = (e) => {
    e?.preventDefault();
    M.Sidenav.getInstance(document.querySelector('#side-navigation')).open();
  };

  openReminders = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(
      document.querySelector('#top-level-reminder-modal')
    ).open();
    M.Sidenav.getInstance(document.querySelector('#side-navigation')).close();
  };

  render = () => (
    <div>
      <div>
        <ul id="side-navigation" className="sidenav">
          <li>
            <div
              className="user-view"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '4px 18px',
              }}
            >
              <Link to="/changelog" className="sidenav-close">
                <img
                  src="/img/orx_logo_flat_no_background.png"
                  style={{
                    height: '68px',
                    width: '200px',
                    paddingLeft: '32px',
                  }}
                  alt="Orx Logo"
                />
              </Link>
            </div>
            <div className="divider" />
          </li>
          <li>
            <ul className="collapsible collapsible-accordion">
              <li>
                <span className="collapsible-header">
                  {this.employeeData?.id
                    ? `${this.employeeData.firstName} ${this.employeeData.lastName}`
                    : 'User Menu'}
                </span>
                <div className="collapsible-body">
                  <ul className="collapsible collapsible-accordion">
                    <li>
                      <Link
                        to="/user/timeclock"
                        className="sidenav-close teir-one"
                      >
                        Time Card
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/user/corrections"
                        className="sidenav-close teir-one"
                      >
                        Correction List
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/user/errortracking"
                        className="sidenav-close teir-one"
                      >
                        Report An Issue
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/user/featurerequest"
                        className="sidenav-close teir-one"
                      >
                        Request A Feature
                      </Link>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="sidenav-close teir-one"
                        onClick={this.openReminders}
                      >
                        Reminders
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span className="collapsible-header">Warehouse</span>
                <div className="collapsible-body">
                  <ul className="collapsible collapsible-accordion">
                    <li>
                      <Link to="/regular" className="sidenav-close teir-one">
                        Order List
                      </Link>
                    </li>
                    <li>
                      <Link to="/redbook" className="sidenav-close teir-one">
                        Redbook
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/indate/sort"
                        className="sidenav-close teir-one"
                      >
                        Indate Sort
                      </Link>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Processing
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/debitmemo"
                              className="sidenav-close teir-two"
                            >
                              Debit Memos
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/indate"
                              className="sidenav-close teir-two"
                            >
                              Indate Processing
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/utility/bottlelookup"
                              className="sidenav-close teir-two"
                            >
                              Bottle Lookup
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/shipping"
                              className="sidenav-close teir-two"
                            >
                              Outbound Shipping
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Controls
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/controls"
                              className="sidenav-close teir-two"
                            >
                              222 Form
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Management
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/warehouse/manager/order/search"
                              className="sidenav-close teir-two"
                            >
                              Order Search
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/pharmacy"
                              className="sidenav-close teir-two"
                            >
                              Pharmacies
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/manufacturer"
                              className="sidenav-close teir-two"
                            >
                              Manufacturers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/wholesaler"
                              className="sidenav-close teir-two"
                            >
                              Wholesalers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/returnhandler"
                              className="sidenav-close teir-two"
                            >
                              Return Handlers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/recall"
                              className="sidenav-close teir-two"
                            >
                              Recalls
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/warehouse/manager/timeclock"
                              className="sidenav-close teir-two"
                            >
                              Timesheet
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span className="collapsible-header">Accounting</span>
                <div className="collapsible-body">
                  <ul className="collapsible collapsible-accordion">
                    <li>
                      <Link to="/accounting" className="sidenav-close teir-one">
                        Customer List
                      </Link>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Invoicing
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/invoice"
                              className="sidenav-close teir-two"
                            >
                              Awaiting Invoice
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/invoice/search"
                              className="sidenav-close teir-two"
                            >
                              Invoice Search
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/invoice/reports"
                              className="sidenav-close teir-two"
                            >
                              Reports
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Wholesaler Credits
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/accounting/wholesaler/credit/report"
                              className="sidenav-close teir-two"
                            >
                              Wholesaler Credit Reports
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/unmatched/credits"
                              className="sidenav-close teir-two"
                            >
                              Unmatched Credits
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/mckesson"
                              className="sidenav-close teir-two"
                            >
                              Mckesson Bull Sheistery
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Checks
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/accounting/outbound/checks/overview"
                              className="sidenav-close teir-two"
                            >
                              Search Checks
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/outbound/checks"
                              className="sidenav-close teir-two"
                            >
                              Write Checks
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Credit Recovery
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/accounting/report/cardinal"
                              className="sidenav-close teir-two"
                            >
                              Cardinal DM Report
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span className="collapsible-header teir-one">
                        Reports
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/accounting/report/newcustomer"
                              className="sidenav-close teir-two"
                            >
                              New Customer Report
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/report/commission"
                              className="sidenav-close teir-two"
                            >
                              Sales Commission
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/creditdetail"
                              className="sidenav-close teir-two"
                            >
                              Credit Detail
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/accounting/report/new_commission"
                              className="sidenav-close teir-two"
                            >
                              New Sales Commission (Brouse)
                            </Link>
                          </li>
                          <li onClick={this.playAudio}>
                            <Link
                              to="/accounting/qbexport"
                              className="sidenav-close teir-two"
                            >
                              QB Export
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span className="collapsible-header">Sales</span>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <Link to="/sales" className="sidenav-close teir-one">
                        Contact Log
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sales/search"
                        className="sidenav-close teir-one"
                      >
                        Pharmacy Import
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span className="collapsible-header">
                  Website Administration
                </span>
                <div className="collapsible-body">
                  <ul className="collapsible collapsible-accordion">
                    <li>
                      <Link
                        to="/website/administration/permission"
                        className="sidenav-close teir-one"
                      >
                        Permissions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/website/administration/customer/portal"
                        className="sidenav-close teir-one"
                      >
                        Customer Portal
                      </Link>
                    </li>
                    {/* <li><Link to="/website/administration/employees" className="sidnav-close teir-one">Employees</Link></li> */}
                    <li>
                      <span className="collapsible-header teir-one">
                        Experimental
                      </span>
                      <div className="collapsible-body">
                        <ul>
                          <li>
                            <Link
                              to="/website/administration/customquery"
                              className="sidenav-close teir-two"
                            >
                              Custom Query
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { sideNavIsOpen } = state;
  return { sideNavIsOpen };
};

export default connect(mapStateToProps)(SideNav);
