import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import { logOut, openSideNav, registerRoutes } from "../../func";
import M from "@materializecss/materialize";
import moment from "moment";
import { IconButton } from "../../layout/form";

class WholesalerCreditReport extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem("auth_data"));
    this.employeeData = JSON.parse(localStorage.getItem("employee_data"));
    this.state = this.initState();
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  initState = () => ({
    wholesalerList: null,
    files: null,
    values: this.initValues(),
    selectedFile: null,
    updateValues: this.initUpdateValues(),
  });

  initValues = () => ({
    filename: "",
    wholesalerId: -1,
    notes: "",
    dateOfReceipt: "",
  });

  initUpdateValues = () => ({
    wholesalerId: -1,
    dateOfReceipt: "",
    dateFundsDeposited: "",
    notes: "",
  });

  componentDidMount = () => {
    this.networkCalls = registerRoutes(
      this.networkCalls,
      this.props.match.path
    );
    this.getFiles();
    this.getWholesalers();
    M.Modal.init(document.querySelectorAll(".modal"));
  };

  networkCalls = {
    getFiles: {
      func: () => {
        Axios.get("/api/v1/creditmemo/read/all/files", {
          params: this.authData,
        })
          .then((result) => this.setState({ files: result.data }))
          .catch(logOut);
      },
      type: "r",
    },
    getWholesalers: {
      func: () => {
        Axios.get("/api/v1/party/wholesaler/read/all/simple", {
          params: this.authData,
        })
          .then((result) => {
            const wholesalerList = result.data.filter((wh) => {
              const wholesalers = ["amerisourcebergen", "cardinal", "mckesson"];
              const name = wh.name.toLowerCase();
              return wholesalers.indexOf(name) !== -1;
            });
            this.setState({ wholesalerList }, () => {
              M.FormSelect.init(document.querySelectorAll("select"));
            });
          })
          .catch(logOut);
      },
      type: "r",
    },
    uploadFile: {
      func: () => {
        const file = document.getElementById("file").files[0];
        const formData = new FormData();

        formData.append("files[]", file);
        formData.append("_id", this.authData._id);
        formData.append("_session_id", this.authData._session_id);
        formData.append("_token", this.authData._token);
        formData.append("user_timezone", this.authData.user_timezone);
        formData.append("wholesalerId", this.state.values.wholesalerId);
        formData.append("uploaderId", this.employeeData.id);
        formData.append("notes", this.state.values.notes);
        formData.append("dateOfReceipt", this.state.values.dateOfReceipt);

        this.setState({ uploadingFiles: true }, () => {
          Axios.post("/api/v1/file/upload/wholesalercreditreport", formData, {
            headers: { "content-type": "multipart/form-data" },
          })
            .then((result) => {
              M.Modal.getInstance(
                document.querySelector("#upload-modal")
              ).close();
              document.getElementById("file").value = "";
              document.getElementById("file-path").value = "";
              this.props.dispatch(result.data);
              this.setState({ values: this.initValues() }, () => {
                M.textareaAutoResize(document.querySelector("#notes"));
                M.updateTextFields();
                M.FormSelect.init(document.querySelector("#wholesalerId"));
              });
            })
            .catch(logOut)
            .finally(() => this.setState({ uploadingFiles: false }));
        });
      },
      type: "c",
    },
    updateFile: {
      func: (data) => {
        Axios.post("/api/v2/wholesalercreditreport/update/one", {
          ...this.authData,
          ...data,
        })
          .then((result) => {
            this.props.dispatch(result.data);
            this.closeEditModal();
            this.networkCalls.getFiles();
          })
          .catch(logOut);
      },
      type: "u",
    },
  };

  getFiles = () => this.networkCalls.getFiles();
  getWholesalers = () => this.networkCalls.getWholesalers();
  uploadFile = (e) => {
    e?.preventDefault();
    this.networkCalls.uploadFile();
  };

  onChange = (e) =>
    this.setState((p) => ({
      values: { ...p.values, [e.target.id]: e.target.value },
    }));

  editChange = ({ target: { value, id } }) =>
    this.setState((prev) => ({
      updateValues: { ...prev.updateValues, [id.split("-")[1]]: value },
    }));

  openUploadModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#upload-modal")).open();
  };

  uploadCancel = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#upload-modal")).close();
    document.getElementById("file").value = "";
    document.getElementById("file-path").value = "";
    this.setState({ values: this.initValues() }, () => {
      M.textareaAutoResize(document.querySelector("#notes"));
      M.updateTextFields();
      M.FormSelect.init(document.querySelector("#wholesalerId"));
    });
  };

  openEditModal = (file) => {
    this.setState(
      {
        selectedFile: file,
        updateValues: {
          wholesalerId: file.wholesalerId,
          dateOfReceipt: file.dateOfReceipt,
          dateFundsDeposited: file.dateFundsDeposited
            ? moment(file.dateFundsDeposited).format("YYYY-MM-DD")
            : "",
          notes: file.notes || "",
        },
      },
      () => {
        M.FormSelect.init(document.querySelector("#edit-wholesalerId"));
        M.Modal.getInstance(document.querySelector("#edit-modal"))?.open();
        M.updateTextFields();
      }
    );
  };

  closeEditModal = (e) => {
    e?.preventDefault();
    this.setState(
      { selectedFile: null, updateValues: this.initUpdateValues() },
      () => {
        document.querySelectorAll("#edit-wholesalerId").forEach((el) => {
          M.FormSelect.getInstance(el)?.destroy();
        });

        M.Modal.getInstance(document.querySelector("#edit-modal"))?.close();
      }
    );
  };

  updateWholsalerCreditReport = (e) => {
    e?.preventDefault();
    this.networkCalls.updateFile({
      ...this.state.selectedFile,
      ...this.state.updateValues,
    });
  };

  render = () => (
    <div className="main">
      <div className="row">
        <div style={{ display: "flex" }}>
          <Link to="/" onClick={openSideNav} style={{ marginRight: "12px" }}>
            <i className="material-icons black-text">menu</i>
          </Link>
          <Link to="/">Home</Link>
          <i className="material-icons">chevron_right</i>
          <Link to="/accounting">Accounting</Link>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">Wholesaler Credit Reports</span>
        </div>
      </div>
      <div className="row">
        <h5 className="center">Wholesaler Credit Reports</h5>
      </div>
      <div className="row">
        <div className="input-field col s12 m2 offset-m10">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={this.openUploadModal}
          >
            Upload File
          </a>
        </div>
      </div>
      <div className="row">
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Wholesaler</th>
              <th>Status</th>
              <th>Received</th>
              <th>Uploaded</th>
              <th>Deposited</th>
              <th>Total Value</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.files &&
              this.state.files.map((f) => (
                <tr key={`file-${f.id}`}>
                  <td style={{ padding: "2px" }}>
                    <Link
                      to={{
                        pathname: `/accounting/wholesaler/credit/report/${f.id}`,
                        state: { filename: f.filename },
                      }}
                    >
                      {f.filename}
                    </Link>
                  </td>
                  <td style={{ padding: "2px" }}>{f.wholesaler.name}</td>
                  <td style={{ padding: "2px" }}>{f.status}</td>
                  <td style={{ padding: "2px" }}>
                    {f.dateOfReceipt
                      ? moment(f.dateOfReceipt).format("MM/DD/YY")
                      : ""}
                  </td>
                  <td style={{ padding: "2px" }}>
                    {moment(f.dateUploaded).format("MM/DD/YYYY")}
                  </td>
                  <td style={{ padding: "2px" }}>
                    {f.dateFundsDeposited
                      ? moment(f.dateFundsDeposited).format("MM/DD/YY")
                      : ""}
                  </td>
                  <td style={{ padding: "2px" }}>
                    {f.total || f.total === 0 ? this.formatter.format(f.total) : ""}
                  </td>
                  <td style={{ padding: "2px" }}>{f.notes}</td>
                  <td style={{ padding: "2px" }}>
                    <IconButton
                      icon="edit"
                      onClick={() => this.openEditModal(f)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div id="upload-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h5>Upload New Wholesaler Report</h5>
          </div>
          <div className="row">
            <div className="file-field col s12">
              <div>
                <div>
                  <input type="file" id="file" name="file" />
                  <label htmlFor="file">Browse Files</label>
                </div>
                <div className="file-path-wrapper">
                  <input id="file-path" className="file-path" type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m4">
              <select
                id="wholesalerId"
                onChange={this.onChange}
                value={this.state.values.wholesalerId}
              >
                <option value={-1}>Select A Wholesaler</option>
                {this.state.wholesalerList &&
                  this.state.wholesalerList.map((wh) => (
                    <option key={`wh-${wh.id}`} value={wh.id}>
                      {wh.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-field col s12 m3">
              <input
                id="dateOfReceipt"
                type="date"
                onChange={this.onChange}
                value={this.state.values.dateOfReceipt}
              />
              <label htmlFor="dateOfReceipt">Date Recieved</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <textarea
                id="notes"
                className="materialize-textarea"
                onChange={this.onChange}
                value={this.state.values.notes}
              />
              <label htmlFor="notes">Notes</label>
            </div>
          </div>
          <div className="row">
            <div className="col s12 m4 l2 right">
              <a
                href="/"
                className="btn-small red white-text waves-effect waves-dark col s12"
                onClick={this.uploadCancel}
              >
                Cancel
              </a>
            </div>
            <div className="col s12 m4 l2 right">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-dark col s12"
                onClick={this.uploadFile}
              >
                Upload
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="edit-modal" className="modal">
        {this.state.selectedFile ? (
          <div className="modal-content">
            <div className="row">
              <h5>{this.state.selectedFile.filename}</h5>
            </div>
            <div className="row">
              <div className="input-field col s12 m4">
                <select
                  id="edit-wholesalerId"
                  onChange={this.editChange}
                  value={this.state.updateValues.wholesalerId}
                >
                  {this.state.wholesalerList?.map((wh) => (
                    <option key={wh.id} value={wh.id}>
                      {wh.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="edit-wholesalerId">Wholesaler</label>
              </div>
              <div className="input-field col s12 m3">
                <input
                  id="edit-dateOfReceipt"
                  type="date"
                  onChange={this.editChange}
                  value={this.state.updateValues.dateOfReceipt}
                />
                <label htmlFor="edit-dateOfReceipt">Date of Receipt</label>
              </div>
              <div className="input-field col s12 m3">
                <input
                  id="edit-dateFundsDeposited"
                  type="date"
                  onChange={this.editChange}
                  value={this.state.updateValues.dateFundsDeposited}
                />
                <label htmlFor="edit-dateFundsDeposited">
                  Date Funds Deposited
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <textarea
                  id="edit-notes"
                  className="materialize-textarea"
                  onChange={this.editChange}
                  value={this.state.updateValues.notes}
                />
                <label htmlFor="edit-notes">Notes</label>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m4 l2 right">
                <a
                  href="/"
                  className="btn-small red white-text waves-effect waves-dark col s12"
                  onClick={this.closeEditModal}
                >
                  Cancel
                </a>
              </div>
              <div className="col s12 m4 l2 right">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-dark col s12"
                  onClick={this.updateWholsalerCreditReport}
                >
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default connect()(WholesalerCreditReport);
