import Axios from 'axios';
import store from '../redux/store';
import { parseJSON } from '../func';

class FileUploader {
  constructor(files, url, nonFileParams) {
    this.groupId = crypto.randomUUID();
    this.files = files;
    this.url = url;
    this.filesReadyToUpload = [];
    this.nonFileParams = nonFileParams || {};
    this.progress = {};
    this.progressCallback = null;
    this.onUploadFinish = null;
  }

  upload = () =>
    new Promise(async (resolve, reject) => {
      try {
        await this.readFiles();
        this.filesReadyToUpload.forEach((file) => {
          this.sendFile(file);
        });
        return resolve();
      } catch (err) {
        this.progress = null;
        if (this.progressCallback) {
          this.progressCallback(this.progress);
        }
        store.dispatch({
          type: 'GLOBAL_TOAST',
          payload: { msg: 'Unable to Upload File(s)', class: 'red white-text' },
        });
        return reject();
      }
    });

  readFiles = () =>
    new Promise(async (resolve, reject) => {
      try {
        const promises = [];
        for (let i = 0; i < this.files.length; i++) {
          const p = this.convertFile(this.files[i]);
          promises.push(p);
        }
        await Promise.all(promises);
        resolve();
      } catch (err) {
        reject(err.toString());
      }
    });

  convertFile = (file) =>
    new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const [prefix, data] = reader.result.split(',');
          const packetSize = 900000;
          const totalPackets = Math.ceil(data.length / packetSize);
          const packets = new Array(totalPackets);

          for (let i = 0, j = 0; i < totalPackets; i++, j += packetSize) {
            packets[i] = data.substr(j, packetSize);
          }

          this.progress[file.name] = { currentPacket: 0, totalPackets };

          const obj = {
            id: crypto.randomUUID(),
            filename: file.name,
            contentType: prefix.split(/:|;/)[1],
            currentPacket: 0,
            totalPackets,
            packets,
          };

          this.filesReadyToUpload.push(obj);
          resolve();
        };
      } catch (err) {
        reject(err.toString());
      }
    });

  sendFile = async (file, currentPacket) => {
    this.progress[file.filename].currentPacket = currentPacket;
    if (this.progressCallback) {
      this.progressCallback(this.progress);
    }

    if (currentPacket === undefined || currentPacket === null)
      return this.sendFile(file, file.currentPacket);
    else if (parseInt(currentPacket) + 1 > parseInt(file.totalPackets)) {
      return console.log('DONE UPLOADING FILE: ', file.filename);
    } else {
      try {
        const response = await this.sendPacket(
          file,
          file.packets[file.currentPacket]
        );
        if (response.data.type === 'GLOBAL_TOAST') {
          store.dispatch(response.data);
          this.progress = null;
          if (
            this.progressCallback &&
            typeof this.progressCallback === 'function'
          ) {
            this.progressCallback(this.progress);
          }
          if (
            this.onUploadFinish &&
            typeof this.onUploadFinish === 'function'
          ) {
            this.onUploadFinish();
          }
        } else if (
          response.data.next === undefined &&
          this.onUploadFinish &&
          typeof this.onUploadFinish === 'function'
        ) {
          this.onUploadFinish(parseJSON(response));
        }

        file.currentPacket++;
        this.sendFile(file, file.currentPacket);
      } catch (err) {
        store.dispatch(err?.response?.data);
        this.progress = null;
        if (this.progressCallback) {
          this.progressCallback(this.progress);
        }
      }
    }
  };

  sendPacket = (file, packet) =>
    Axios.post(this.url, {
      ...JSON.parse(localStorage.getItem('auth_data')),
      ...this.nonFileParams,
      groupId: this.groupId,
      fileCount: this.files.length,
      id: file.id,
      filename: file.filename,
      contentType: file.contentType,
      currentPacket: file.currentPacket,
      totalPackets: file.totalPackets,
      packet,
    });

  setProgressCallback = (callback) => (this.progressCallback = callback);
  setOnUploadFinish = (callback) => (this.onUploadFinish = callback);
}

export default FileUploader;
