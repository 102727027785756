import { useEffect, useMemo, useState } from 'react';
import { logOut, registerRoutes } from '../../../func';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import M from '@materializecss/materialize';
import moment from 'moment';
import { IconButton } from '../../../layout/form';

const ExportTable = ({ exportType }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const authData = useMemo(
    () => JSON.parse(localStorage.getItem('auth_data')),
    []
  );

  const initValues = () => ({
    year: moment().year().toString(),
    startMonth: (moment().month() + 1).toString(),
    endMonth: (moment().month() + 2).toString(),
  });

  const [values, setValues] = useState(initValues());
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);

  const networkCalls = useMemo(
    () =>
      registerRoutes(
        {
          getReports: {
            func: () => {
              setLoading(true);
              Axios.get('/api/v2/quickbooks/read/many/exports/by/type', {
                params: {
                  ...authData,
                  exportType,
                },
              })
                .then((result) => setReportList(result.data))
                .catch(logOut)
                .finally(() => setLoading(false));
            },
            type: 'r',
          },
          createReport: {
            func: (data) => {
              Axios.post('/api/v2/quickbooks/create/one/export', {
                ...authData,
                ...data,
              })
                .then((result) => {
                  networkCalls.getReports();
                  const { csv, filename } = result.data;
                  const buf = new Uint8Array(csv.data);
                  const blob = new Blob([buf]);
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.hidden = true;
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                })
                .catch(logOut);
            },
          },
          finalizeExport: {
            func: (data) => {
              Axios.post('/api/v2/quickbooks/update/one/finalizeexport', {
                ...authData,
                ...data,
              })
                .then((result) => {
                  networkCalls.getReports();
                  dispatch(result.data);
                })
                .catch(logOut);
            },
            type: 'u',
          },
          deleteExport: {
            func: (data) => {
              Axios.post('/api/v2/quickbooks/delete/one/export/by/id', {
                ...authData,
                ...data,
              })
                .then((result) => {
                  networkCalls.getReports();
                  dispatch(result.data);
                })
                .catch(logOut);
            },
            type: 'd',
          },
          downloadReport: {
            func: (data) => {
              Axios.get('/api/v2/quickbooks/download/one/export/by/id', {
                params: {
                  ...authData,
                  ...data,
                },
              })
                .then((result) => {
                  networkCalls.getReports();
                  const { buffer, file } = result.data;
                  const { filename } = file;
                  const blob = new Blob([buffer]);
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.hidden = true;
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                })
                .catch(logOut);
            },
            type: 'r',
          },
        },
        pathname
      ),
    [pathname, exportType]
  );

  const initYears = () => {
    const thisYear = moment().year();
    const years = [];
    for (let i = 2020; i <= thisYear; i++) years.push(i);
    return years;
  };

  const initMonths = () => {
    const months = [];
    for (let i = 1; i < 13; i++)
      months.push({
        value: i,
        label: moment(`0001-${i.toString().padStart(2, '0')}-01`).format(
          'MMMM'
        ),
      });
    return months;
  };

  useEffect(() => {
    networkCalls.getReports();
    setYears(initYears());
    setMonths(initMonths());
    M.FormSelect.init(document.querySelectorAll('select'));
  }, [exportType]);

  useEffect(() => {
    M.FormSelect.init(document.querySelectorAll('select'));
  }, [years, months]);

  const onChange = ({ target: { id, value } }) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const generateReport = (e) => {
    e?.preventDefault();
    const { year, startMonth, endMonth } = values;
    const formattedStartDate = moment(
      `${year}-${startMonth.padStart(2, '0')}-01`
    ).format('YYYY-MM-DD 00:00:00');
    const formattedEndDate = moment(`${year}-${endMonth.padStart(2, '0')}-01`)
      .endOf('month')
      .format('YYYY-MM-DD 23:59:59');

    networkCalls.createReport({
      exportType: exportType.toLowerCase(),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const finalizeReport = (e, report) => {
    e?.preventDefault();
    networkCalls.finalizeExport({ id: report.id });
  };

  const downloadReport = (e, report) => {
    e?.preventDefault();
    networkCalls.downloadReport({ id: report.id });
  };

  const deleteReport = (e, report) => {
    e?.preventDefault();
    networkCalls.deleteExport({ id: report.id });
  };

  return (
    <div style={{ padding: '1vw' }}>
      <div className="row">
        {exportType !== 'customers' && (
          <>
            <div className="input-field col s12 m2">
              <select id="year" value={values.year} onChange={onChange}>
                {years?.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <label htmlFor="year">Year</label>
            </div>
            <div className="input-field col s12 m2">
              <select
                id="startMonth"
                value={values.startMonth}
                onChange={onChange}
              >
                {months?.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <label htmlFor="startMonth">Start Month</label>
            </div>
            <div className="input-field col s12 m2">
              <select id="endMonth" value={values.endMonth} onChange={onChange}>
                {months?.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <label htmlFor="endMonth">End Month</label>
            </div>
          </>
        )}
        <div className="input-field col s12 m3">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={generateReport}
          >
            Generate Report
          </a>
        </div>
      </div>
      {loading ? (
        <div className="progress white">
          <div className="indeterminate blue" />
        </div>
      ) : (
        <table className="highlight">
          <thead>
            <tr>
              <th>Type</th>
              <th>Created By</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reportList?.map((report) => (
              <tr key={report.id}>
                <td>{report.exportType}</td>
                <td>
                  {report.createdBy?.firstName} {report.createdBy?.lastName}
                </td>
                <td>{moment(report.startDate).format('MM/DD/YYYY')}</td>
                <td>{moment(report.endDate).format('MM/DD/YYYY')}</td>
                <td>
                  <IconButton
                    icon="download"
                    onClick={(e) => downloadReport(e, report)}
                    tooltip="download"
                  />
                  {report.isFinal ? (
                    ''
                  ) : (
                    <>
                      <IconButton
                        icon="save"
                        onClick={(e) => finalizeReport(e, report)}
                        tooltip="finalize"
                      />
                      <IconButton
                        icon="delete"
                        onClick={(e) => deleteReport(e, report)}
                        tooltip="delete"
                      />
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExportTable;
