import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';
import moment from 'moment';

class CreateInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
    this.orderNumber = this.props.location.state
      ? this.props.location.state.orderNumber
      : '';
    this.state = this.initState();
  }

  initState = () => ({
    order: null,
    fees: null,
    minMet: null,
    invoiceNote: '',
    values: this.initValues(),
    orderNumberSearch: '',
  });

  initValues = () => ({
    code: '',
    description: '',
    quantity: '',
    rate: '',
  });

  componentDidMount = () => {
    this.networkCalls = registerRoutes(
      this.networkCalls,
      this.props.match.path
    );
    if (this.orderNumber) this.getOrder();
  };

  networkCalls = {
    getOrder: {
      func: () => {
        this.setState({ loading: true }, () => {
          Axios.get('/api/v1/invoice/read/one/by/ordernumber', {
            params: {
              ...this.authData,
              orderNumber: this.orderNumber,
            },
          })
            .then((result) => this.calcCharges(result.data))
            .catch(logOut)
            .finally(() =>
              this.setState({ loading: false }, () => {
                M.FormSelect.init(document.querySelectorAll('select'));
              })
            );
        });
      },
      type: 'r',
    },
    save: {
      func: () => {
        this.setState({ saving: true }, () => {
          const { fees, invoiceTotal, noteToCustomer } = this.state;
          Axios.post('/api/v1/invoice/create/one', {
            ...this.authData,
            orderNumber: this.orderNumber,
            fees,
            invoiceTotal,
            noteToCustomer,
            createdById: this.employeeData.id,
          })
            .then((result) => {
              this.props.dispatch(result.data);
              this.props.history.replace('/invoice');
            })
            .catch(logOut)
            .finally(() => this.setState({ saving: false }));
        });
      },
      type: 'c',
    },
  };

  getOrder = () => this.networkCalls.getOrder();
  save = (e) => {
    e?.preventDefault();
    this.networkCalls.save();
  };

  onChange = (e) => this.setState({ [e.target.id]: e.target.value });
  valueChange = (e) =>
    this.setState((p) => ({
      values: { ...p.values, [e.target.id]: e.target.value },
    }));

  calcCharges = (order) => {
    const fees = [];

    order.invoiceTemplate.forEach((row) => {
      switch (row.calcType) {
        case 'Percent of Estimated Credits':
          fees.push({
            ...row,
            rate: order.outdateFeeOverride,
            quantity: order.estimatedValue || 0,
            total: order.outdateFee || 0,
          });
          break;
        case 'Flat Fee':
          fees.push({ ...row, quantity: 1, total: row.rate });
          break;
        case 'Weight of Regular Destruction':
          fees.push({
            ...row,
            quantity: order.trashInLbs || 0,
            total: row.rate * order.trashInLbs || 0,
          });
          break;
        case 'Weight of RCRA':
          fees.push({
            ...row,
            quantity: (order.rcraInLbs || 0) + (order.controlTrashInLbs || 0),
            total:
              row.rate *
              ((order.rcraInLbs || 0) + (order.controlTrashInLbs || 0)) || 0,
          });
          break;
        case 'Number of Boxes':
          fees.push({
            ...row,
            quantity: order.incomingShipments || 0,
            total: row.rate * order.incomingShipments || 0,
          });
          break;
        default:
          break;
      }
    });

    this.setState({ order, fees }, () => this.calculateTotal(fees));
  };

  removeLine = (index) => {
    const fees = JSON.parse(JSON.stringify(this.state.fees));
    fees.splice(index, 1);

    this.setState({ fees }, () => this.calculateTotal(fees));
  };

  calculateTotal = (fees) => {
    let sumTotal = 0;
    let minMet = true;
    const min = fees.filter((row) => row.code === 'Minimum Service Fee');

    fees.forEach(
      (row) =>
        (sumTotal +=
          row.code === 'Minimum Service Fee' ? 0 : parseFloat(row.total))
    );

    if (min.length) {
      if (min[0].rate > sumTotal) minMet = false;
    }

    this.setState(
      {
        minMet,
        invoiceTotal: minMet ? sumTotal : parseFloat(min[0].rate),
        noteToCustomer: minMet ? '' : 'Minimum Fee',
      },
      () => {
        M.textareaAutoResize(document.querySelector('#noteToCustomer'));
        M.updateTextFields();
      }
    );
  };

  addLine = (e) => {
    e?.preventDefault();

    const fees = JSON.parse(JSON.stringify(this.state.fees));
    fees.push({
      ...this.state.values,
      total: parseFloat(this.state.values.rate * this.state.values.quantity),
    });

    this.setState({ fees, values: this.initValues() }, () => {
      this.calculateTotal(fees);
      M.FormSelect.init(document.querySelectorAll('select'));
    });
  };

  getCodes = () => [
    'Basic Service Fee',
    'Minimum Service Fee',
    'Regular Destruction',
    'RCRA Destruction',
    'Shipping',
    'Other',
  ];

  orderLookup = (event) => {
    event?.preventDefault();
    this.orderNumber = this.state.orderNumberSearch;
    this.networkCalls.getOrder();
  };

  render = () => (
    <div className="main">
      <div className="row">
        <div style={{ display: 'flex' }}>
          <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}>
            <i className="material-icons black-text">menu</i>
          </Link>
          <Link to="/">Home</Link>
          <i className="material-icons">chevron_right</i>
          <Link to="/accounting">Accounting</Link>
          <i className="material-icons">chevron_right</i>
          <Link to="/invoice">Invoicing</Link>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">{this.orderNumber}</span>
        </div>
      </div>
      <div className="row">
        <h5 className="center">New Invoice</h5>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              {this.state.order ? (
                <div>
                  <div className="row">
                    <div className="input-field col s12 m2">
                      <select
                        id="code"
                        onChange={this.valueChange}
                        value={this.state.values.code}
                      >
                        <option value={''}></option>
                        {this.getCodes().map((c) => (
                          <option key={`code-${c}`} value={c}>
                            {c}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="code">Code</label>
                    </div>
                    <div className="input-field col s12 m4">
                      <input
                        id="description"
                        type="text"
                        onChange={this.valueChange}
                        value={this.state.values.description}
                      />
                      <label htmlFor="description">Description</label>
                    </div>
                    <div className="input-field col s12 m1">
                      <input
                        id="rate"
                        type="number"
                        onChange={this.valueChange}
                        value={this.state.values.rate}
                      />
                      <label htmlFor="rate">Rate</label>
                    </div>
                    <div className="input-field col s12 m1">
                      <input
                        id="quantity"
                        type="number"
                        onChange={this.valueChange}
                        value={this.state.values.quantity}
                      />
                      <label htmlFor="quantity">Qty</label>
                    </div>
                    <div className="input-field col s12 m2 offset-m2">
                      <a
                        href="/"
                        className="btn-small blue white-text col s12 waves-effect waves-light"
                        onClick={this.addLine}
                      >
                        Add Line Item
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="input-field col s12 m4 xl2">
                    <input
                      type="text"
                      value={this.state.orderNumberSearch}
                      onChange={({ target: { value } }) =>
                        this.setState({ orderNumberSearch: value })
                      }
                      onKeyDown={({ key }) => {
                        if (key === 'Enter') this.orderLookup();
                      }}
                    />
                    <label>Order Number</label>
                  </div>
                  <div className="input-field col s12 m4 xl2">
                    <a
                      href="/"
                      className="btn-small blue white-text waves-effect waves-light col s12"
                      onClick={this.orderLookup}
                    >
                      Lookup Order
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="card">
            {this.state.order && (
              <div className="card-content">
                <div className="row">
                  <h5>
                    {this.state.order.pharmacyName} -{' '}
                    {this.state.order.orderNumber}
                  </h5>
                  <h6>
                    {moment(this.state.order.orderDate).format('MM/DD/YYYY')}
                  </h6>
                </div>
                <div className="row">
                  <table>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Rate</th>
                        <th>Quantity</th>
                        <th style={{ textAlign: 'right' }}>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fees.map((row, index) => {
                        if (row.code === 'Minimum Service Fee')
                          return (
                            <tr key={`fee-${index}`}>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? 'line-through'
                                    : '',
                                }}
                              >
                                {row.code}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? 'line-through'
                                    : '',
                                }}
                              >
                                {row.description}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? 'line-through'
                                    : '',
                                }}
                              >
                                ${row.rate}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? 'line-through'
                                    : '',
                                }}
                              >
                                {row.quantity}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? 'line-through'
                                    : '',
                                  textAlign: 'right',
                                }}
                              >
                                ${row.total?.toFixed(2)}
                              </td>
                              <td
                                style={{ padding: '4px', textAlign: 'right' }}
                              >
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="material-icons red-text"
                                  onClick={() => this.removeLine(index)}
                                >
                                  delete
                                </i>
                              </td>
                            </tr>
                          );
                        else if (
                          row.calcType === 'Percent of Estimated Credits'
                        )
                          return (
                            <tr key={`fee-${index}`}>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.code}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.description}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.rate}%
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                ${row.quantity}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                  textAlign: 'right',
                                }}
                              >
                                ${row.total?.toFixed(2)}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                  textAlign: 'right',
                                }}
                              >
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="material-icons red-text"
                                  onClick={() => this.removeLine(index)}
                                >
                                  delete
                                </i>
                              </td>
                            </tr>
                          );
                        else
                          return (
                            <tr key={`fee-${index}`}>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.code}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.description}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                ${row.rate}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                }}
                              >
                                {row.quantity}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                  textAlign: 'right',
                                }}
                              >
                                ${row.total?.toFixed(2)}
                              </td>
                              <td
                                style={{
                                  padding: '4px',
                                  textDecoration: this.state.minMet
                                    ? ''
                                    : 'line-through',
                                  textAlign: 'right',
                                }}
                              >
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="material-icons red-text"
                                  onClick={() => this.removeLine(index)}
                                >
                                  delete
                                </i>
                              </td>
                            </tr>
                          );
                      })}
                      <tr style={{ borderTop: '2px black solid' }}>
                        <td style={{ padding: '4px' }}></td>
                        <td style={{ padding: '4px' }}></td>
                        <td style={{ padding: '4px' }}></td>
                        <td style={{ padding: '4px' }}></td>
                        <td
                          style={{
                            padding: '4px',
                            textAlign: 'right',
                            fontWeight: '700',
                          }}
                        >
                          ${this.state.invoiceTotal?.toFixed(2)}
                        </td>
                        <td style={{ padding: '4px' }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row" style={{ marginBottom: '80px' }}>
                  <div className="input-field col s12 m8">
                    <textarea
                      id="noteToCustomer"
                      className="materialize-textarea"
                      onChange={this.onChange}
                      value={this.state.noteToCustomer}
                    />
                    <label htmlFor="noteToCustomer">Note To Customer</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m2 offset-m10">
                    <a
                      href="/"
                      className={`btn-small green white-text waves-effect waves-light col s12 ${
                        this.state.saving ? 'disabled' : ''
                      }`}
                      onClick={this.save}
                    >
                      Save Invoice
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(CreateInvoice);
