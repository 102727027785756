import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';
import * as xlsx from 'xlsx';
import moment from 'moment';
import FileUploader from '../../utilities/fileUploader';

class McKesson extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    this.state = this.initState();
  }

  initState = () => ({
    processing: false,
    sheet: null,
    mfrNameList: null,
    mfrList: null,
    pharmacies: null,
    credits: null,
    invoices: null,
    unmatchedCredits: null,
  });

  componentDidMount = () => {
    this.networkCalls = registerRoutes(
      this.networkCalls,
      this.props.match.path
    );
    M.Modal.init(document.querySelectorAll('.modal'));
  };

  networkCalls = {
    downloadTemplate: {
      func: () => {
        this.setState({ downloading: true }, () => {
          Axios.get('/api/v1/creditmemo/read/creditmemotemplate', {
            params: this.authData,
            responseType: 'blob',
          })
            .then((result) => {
              const blob = new Blob([result.data]);
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.hidden = true;
              link.href = url;
              link.setAttribute('download', 'credit_memo_template.xlsx');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(logOut)
            .finally(() => this.setState({ downloading: false }));
        });
      },
      type: 'r',
    },
    resolveManufacturerNames: {
      func: async (names) => {
        M.Modal.getInstance(document.querySelector('#upload-modal')).close();
        if (!this.state.mfrList) await this.getManufacturerNames();

        Axios.get('/api/v1/creditmemo/read/all/aliases', {
          params: this.authData,
        })
          .then((result) => {
            const aliases = result.data.map((row) => row.alias);

            const arr = [];
            const mfrNameList = [];
            names.forEach((name) => {
              if (arr.indexOf(name) === -1) arr.push(name);
            });

            arr.forEach((name) => {
              if (
                name &&
                aliases
                  .map((name) => name.toLowerCase())
                  .indexOf(name.toLowerCase()) === -1
              )
                mfrNameList.push({ name, id: -1 });
            });

            this.setState({ mfrNameList: mfrNameList }, () => {
              if (this.state.mfrNameList.length)
                M.Modal.getInstance(
                  document.querySelector('#mfr-modal')
                ).open();
              else this.matchCredits();
            });
          })
          .catch(logOut);
      },
      type: 'u',
    },
    getManufacturerNames: {
      func: () =>
        new Promise((resolve) => {
          Axios.get('/api/v1/party/manufacturer/read/all', {
            params: this.authData,
          })
            .then((result) => {
              const mfrList = [];
              const dbNames = [];
              result.data.forEach((row) => {
                mfrList.push({ id: row.id, name: row.name });
                dbNames.push(row.name);
              });

              this.setState({ mfrList, dbNames }, () => resolve());
            })
            .catch(logOut);
        }),
      type: 'r',
    },
    submitNames: {
      func: () => {
        let mfrList = JSON.parse(JSON.stringify(this.state.mfrNameList));
        const arr = mfrList.filter((mfr) => mfr.id);

        Axios.post('/api/v1/creditmemo/create/many/manufactureraliases', {
          ...this.authData,
          mfrNameList: arr,
        })
          .then((result) => {
            this.props.dispatch(result.data);
            M.Modal.getInstance(document.querySelector('#mfr-modal')).close();
            this.matchCredits();
          })
          .catch(logOut);
      },
      type: 'c',
    },
    matchCredits: {
      func: () => {
        this.setState({ processing: true }, () => {
          const file = document.getElementById('file').files[0];
          const fileUploader = new FileUploader(
            [file],
            '/api/v1/file/upload/mckesson'
          );

          fileUploader.setOnUploadFinish((result) => {
            this.setState({ matchResult: result.data }, () => {
              this.sortData(result.data.credits, result.data.pharmacies);
            });
          });

          fileUploader
            .upload()
            .finally(() =>
              this.setState({ processing: false }, this.closeUploadModal)
            );
        });
      },
      type: 'u',
    },
    updateUnmatchedCredits: {
      func: () => {
        const unmatchedCredits = JSON.parse(
          JSON.stringify(this.state.unmatchedCredits)
        );
        const ids = [];
        const stillUnmatched = [];

        unmatchedCredits.forEach((c) => {
          if (c.debitMemoId) ids.push(c.debitMemoId);
          else {
            stillUnmatched.push(c);
          }
        });

        Axios.get('/api/v1/creditmemo/read/manuallymatchmckessoncredits', {
          params: {
            ...this.authData,
            ids,
          },
        })
          .then((result) => {
            result.data.forEach((row) => {
              const index = unmatchedCredits.findIndex(
                (uc) => parseInt(row.debitMemo.id) === parseInt(uc.debitMemoId)
              );
              unmatchedCredits[index].debitMemo = row.debitMemo;
              unmatchedCredits[index].orderHeader = row.orderHeader;
            });
            const { credits, pharmacies } = JSON.parse(
              JSON.stringify(this.state.matchResult)
            );
            const indices = [];

            credits.forEach((credit, cIndex) => {
              unmatchedCredits.forEach((unmatchedCredit, uIndex) => {
                if (
                  credit.creditMemoNumber === unmatchedCredit.creditMemoNumber
                ) {
                  indices.push({ cIndex, uIndex });
                }
              });
            });

            indices.forEach(
              (i) => (credits[i.cIndex] = unmatchedCredits[i.uIndex])
            );

            this.setState({ unmatchedCredits: stillUnmatched }, () => {
              this.sortData(credits, pharmacies);
            });
          })
          .catch(logOut);
      },
      type: 'u',
    },
  };

  downloadTemplate = (e) => {
    e?.preventDefault();
    this.networkCalls.downloadTemplate();
  };
  resolveManufacturerNames = async (names) =>
    this.networkCalls.resolveManufacturerNames(names);
  getManufacturerNames = () => this.networkCalls.getManufacturerNames();
  submitNames = (e) => {
    e?.preventDefault();
    this.networkCalls.submitNames();
  };
  matchCredits = () => this.networkCalls.matchCredits();
  updateUnmatchedCredits = (e) => {
    e?.preventDefault();
    this.networkCalls.updateUnmatchedCredits();
  };

  openUploadModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector('#upload-modal')).open();
  };

  closeUploadModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector('#upload-modal')).close();
    document.getElementById('file').value = '';
    document.getElementById('file-path').value = '';
    M.updateTextFields();
  };

  uploadCredits = (e) => {
    e?.preventDefault();

    const file = document.getElementById('file').files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = xlsx.read(data, { type: 'array' });
      this.displaySheet(workbook);
    };
    reader.readAsArrayBuffer(file);
  };

  displaySheet = (wb) => {
    const sheet = xlsx.utils.sheet_to_json(wb.Sheets['Credit Report Upload']);
    const mfrNameList = [];
    sheet.forEach((row) => mfrNameList.push(row['Reported Manufacturer Name']));
    this.setState({ sheet }, () => this.resolveManufacturerNames(mfrNameList));
  };

  setMfrId = (index, id) => {
    const mfrNameList = JSON.parse(JSON.stringify(this.state.mfrNameList));
    mfrNameList[index].id = id;
    this.setState({ mfrNameList });
  };

  sortData = (credits, pharmacies) => {
    const matchedCredits = [];
    const unmatchedCredits = [];

    pharmacies.forEach((p) => {
      p.total = 0;
      p.combinedCreditsTotal = 0;
      p.values = {
        totalAmount: 0,
        remainingBalance: 0,
      };
      p.credits = [];
      p.invoices.forEach((i) => {
        i.availableCreditAmount = 0;
        i.credits = [];
        i.values = {};
        p.total += parseFloat(parseFloat(i.total).toFixed(2));
        credits.forEach((c, index) => {
          if (c.orderHeader.id === i.orderHeaderId) {
            i.credits.push(c);
            i.availableCreditAmount += parseFloat(
              parseFloat(c.availableCreditAmount || 0).toFixed(2)
            );
            matchedCredits.push(index);
            p.credits.push(c);
          }
        });
        const eighty = parseFloat(
          parseFloat(i.availableCreditAmount * 0.8).toFixed(2)
        );
        i.values.amount =
          eighty > i.total
            ? eighty
            : i.total > i.availableCreditAmount
            ? parseFloat(i.availableCreditAmount.toFixed(2))
            : i.total;
        i.values.percent =
          i.availableCreditAmount > 0
            ? parseFloat(
                parseFloat(
                  parseInt(i.values.amount * 100) /
                    parseInt(i.availableCreditAmount * 100)
                ).toFixed(2)
              )
            : 0;
        p.combinedCreditsTotal += i.availableCreditAmount;
        p.values.totalAmount += i.values.amount;
      });
      p.values.remainingBalance = parseFloat(
        p.total - p.values.totalAmount
      ).toFixed(2);
    });

    credits.forEach((c, index) => {
      if (matchedCredits.indexOf(index) === -1) {
        unmatchedCredits.push(c);
      }
    });

    const pharmaciesFinal = [];

    pharmacies.forEach((p, index) => {
      if (p.credits.length) pharmaciesFinal.push(p);
    });
    this.setState({ pharmacies: pharmaciesFinal, unmatchedCredits });
  };

  onChange = (e) => {
    const { value, id } = e.target;
    if (value < 0) {
      return;
    }
    const [type, pIndex, iIndex] = id.split('-');
    const pharmacies = JSON.parse(JSON.stringify(this.state.pharmacies));
    const pharmacy = pharmacies[pIndex];
    const { availableCreditAmount } = pharmacy.invoices[iIndex];

    if (type === 'amount') {
      if (value > availableCreditAmount) {
        return;
      }
      pharmacy.invoices[iIndex].values.amount = value;
      pharmacy.invoices[iIndex].values.percent = parseFloat(
        Math.round(parseInt((value / availableCreditAmount) * 100)) / 100
      );
    } else {
      if (value > 1) {
        return;
      }
      pharmacy.invoices[iIndex].values.percent = value;
      pharmacy.invoices[iIndex].values.amount = parseFloat(
        Math.round(parseInt(availableCreditAmount * value * 100)) / 100
      );
    }
    let totalAmount = 0;
    pharmacy.invoices.forEach(
      (i) => (totalAmount += parseFloat(i.values.amount || 0))
    );

    pharmacy.values.totalAmount = totalAmount.toFixed(2);
    pharmacy.values.remainingBalance = parseFloat(
      pharmacy.total - totalAmount
    ).toFixed(2);

    pharmacies[pIndex] = pharmacy;
    this.setState({ pharmacies });
  };

  createExcel = (e) => {
    e?.preventDefault();
    const vendorInformation = [];
    const customerInformation = [];

    this.state.pharmacies.forEach((p) => {
      p.invoices.forEach((i) => {
        const arr = p.wholesalerAccounts.filter(
          (acct) => acct.wholesalerName.toLowerCase().search('mckesson') !== -1
        );
        const acct = arr.length
          ? arr[0].accountNumber
          : p.wholesalerAccounts[0].accountNumber;

        i.credits.forEach((c) => {
          const vi = {
            'Batch Date': `ORX${moment(c.debitMemo.dateCreated).format(
              'MMDD'
            )}`,
            'AP Number': c.wholesalerDocumentNumber,
            'Manufacturer Name': c.reportedManufacturerName,
            'Credit Memo Number': c.creditMemoNumber,
            'Credit Issue Date': moment(c.wholesalerProcessDate).format(
              'MM/DD/YYYY'
            ),
            'Gross Total': c.fullCreditAmount,
            'Packing Slip': c.reportedDebitMemoNumber,
            'Process Fee': parseFloat(
              (c.availableCreditAmount * i.values.percent).toFixed(2)
            ),
            'MCK Fee': parseFloat(
              (c.fullCreditAmount - c.availableCreditAmount).toFixed(2)
            ),
          };

          const ci = {
            'Batch Date': `ORX${moment(c.debitMemo.dateCreated).format(
              'MMDD'
            )}`,
            'Customer Number': acct,
            'Pharmacy Name': p.pharmacyName.toUpperCase(),
            'Net Credit': parseFloat(
              (vi['Gross Total'] - vi['MCK Fee'] - vi['Process Fee']).toFixed(2)
            ),
            'DEA Number': p.pharmacyDeaNumber,
            'Corrected Debit Memo Number': `ORX${c.debitMemo.prefix || ''}${
              c.debitMemo.month
            }${c.debitMemo.control === 'R' ? '' : 'C'}${c.debitMemo.number
              ?.toString()
              .padStart(4, '0')}`,
            'Credit Memo Number': c.creditMemoNumber,
          };

          vendorInformation.push(vi);
          customerInformation.push(ci);
        });
      });
    });
    const wb = xlsx.utils.book_new();
    const viSheet = xlsx.utils.json_to_sheet(vendorInformation);
    const ciSheet = xlsx.utils.json_to_sheet(customerInformation);
    const viSheetWidths = {};

    Object.keys(viSheet).forEach((key) => {
      const [col, row] = key.match(/[^\d]+|\d+/g);
      if (col.search('!') === -1) {
        const len = viSheet[key].v?.toString().length;
        if (!viSheetWidths[col]) viSheetWidths[col] = [];

        viSheetWidths[col].push(len);

        if (parseInt(row) > 1) {
          switch (col) {
            case 'E':
              viSheet[key].t = 'd';
              break;
            case 'F':
              viSheet[key].t = 'n';
              viSheet[key].z = '0.00';
              break;
            case 'H':
              viSheet[key].t = 'n';
              viSheet[key].z = '0.00';
              break;
            case 'I':
              viSheet[key].t = 'n';
              viSheet[key].z = '0.00';
              break;
            default:
              break;
          }
        }
      }
    });
    const viSheetWidthsFinal = [];

    Object.keys(viSheetWidths).forEach((key) => {
      viSheetWidthsFinal.push({
        wch: Math.max(...viSheetWidths[key]),
      });
    });

    viSheet['!cols'] = viSheetWidthsFinal;

    const ciSheetWidths = {};

    Object.keys(ciSheet).forEach((key) => {
      const [col, row] = key.match(/[^\d]+|\d+/g);
      if (col.search('!') === -1) {
        const len = ciSheet[key].v?.toString().length;
        if (!ciSheetWidths[col]) ciSheetWidths[col] = [];

        ciSheetWidths[col].push(len);

        if (row > 1 && col === 'D') {
          ciSheet[key].t = 'n';
          ciSheet[key].z = '0.00';
        }
      }
    });
    const ciSheetWidthsFinal = [];

    Object.keys(ciSheetWidths).forEach((key) => {
      ciSheetWidthsFinal.push({
        wch: Math.max(...ciSheetWidths[key]),
      });
    });

    ciSheet['!cols'] = ciSheetWidthsFinal;

    xlsx.utils.book_append_sheet(wb, viSheet, 'Vendor Information');
    xlsx.utils.book_append_sheet(wb, ciSheet, 'Customer Information');
    xlsx.writeFile(wb, 'mckesson_credit_report.xlsx');
  };

  openUnmatchedCreditsModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(
      document.querySelector('#unmatched-credits-modal')
    ).open();
  };

  unmatchedInputChange = (e) => {
    const index = e.target.id.split('-')[2];
    const unmatchedCredits = JSON.parse(
      JSON.stringify(this.state.unmatchedCredits)
    );
    unmatchedCredits[index].debitMemoId = e.target.value;
    this.setState({ unmatchedCredits }, () =>
      document.querySelector(`#unmatched-input-${index}`).focus()
    );
  };

  render = () => (
    <div className="main">
      <div className="row">
        <div style={{ display: 'flex' }}>
          <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}>
            <i className="material-icons black-text">menu</i>
          </Link>
          <Link to="/">Home</Link>
          <i className="material-icons">chevron_right</i>
          <Link to="/accounting">Accounting</Link>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">McKesson B-Sheistery</span>
        </div>
      </div>
      <div>
        <div className="row">
          <h5 className="center">McKesson Credits</h5>
        </div>
        <div className="row">
          <div className="col s12 m4 l2 offset-m4 offset-l8">
            <a
              href="/"
              className="btn-small purple white-text waves-effect waves-light col s12"
              onClick={this.openUploadModal}
            >
              Upload Mckesson Credits
            </a>
          </div>
          <div className="col s12 m4 l2">
            <a
              href="/"
              className="btn-small cyan white-text waves-effect waves-light col s12"
              onClick={this.downloadTemplate}
            >
              Download Credit Template
            </a>
          </div>
        </div>
        <div className="row">
          {this.state.pharmacies && (
            <div className="col s12 m8 l4 offset-m4 offset-l8">
              <a
                href="/"
                className="btn-small teal white-text waves-effect waves-light col s12"
                onClick={this.createExcel}
              >
                Create Excel File
              </a>
            </div>
          )}
        </div>
        <div className="row">
          {this.state.unmatchedCredits?.length ? (
            <div className="col s12 m8 l4 offset-m4 offset-l8">
              <a
                href="/"
                className="btn-small red white-text waves-effect waves-light col s12"
                onClick={this.openUnmatchedCreditsModal}
              >
                View Unmatched Credits
              </a>
            </div>
          ) : null}
        </div>
        {this.state.pharmacies &&
          this.state.pharmacies.map((p, pIndex) => (
            <div className="row" key={p.pharmacyName + p.id}>
              <div className="col s12">
                <div className="card">
                  <div className="card-content">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ padding: '4px' }}>{p.pharmacyName}</th>
                          <th style={{ textAlign: 'right', padding: '4px' }}>
                            Balance:{' '}
                            <span
                              className={
                                p.values.remainingBalance > 0
                                  ? 'orange-text'
                                  : 'green-text'
                              }
                            >
                              $
                              {parseFloat(p.values.remainingBalance).toFixed(2)}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <table className="striped">
                              <thead>
                                <tr>
                                  <th style={{ padding: '2px' }}>Order #</th>
                                  <th style={{ padding: '2px' }}>Order Date</th>
                                  <th style={{ padding: '2px' }}>
                                    Combined Credits
                                  </th>
                                  <th style={{ padding: '2px' }}>Amount Due</th>
                                  <th style={{ padding: '2px' }}></th>
                                  <th style={{ padding: '2px' }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {p.invoices.map((i, iIndex) => (
                                  <tr key={`${p.id}-${i.id}`}>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      {i.orderNumber}
                                    </td>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      {moment(i.orderDate).format('MM/DD/YYYY')}
                                    </td>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      {(i.availableCreditAmount || 0).toFixed(
                                        2
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      {(i.total || 0).toFixed(2)}
                                    </td>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      <input
                                        className="browser-default"
                                        style={{
                                          margin: '0px',
                                          padding: '0px',
                                        }}
                                        type="number"
                                        id={`amount-${pIndex}-${iIndex}`}
                                        onChange={this.onChange}
                                        value={
                                          this.state.pharmacies[pIndex]
                                            .invoices[iIndex].values.amount
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{
                                        padding: '2px',
                                        borderBottom:
                                          iIndex === p.invoices.length - 1
                                            ? 'solid black 1px'
                                            : '',
                                      }}
                                    >
                                      <input
                                        style={{
                                          margin: '0px',
                                          padding: '0px',
                                        }}
                                        className="browser-default"
                                        type="number"
                                        id={`percent-${pIndex}-${iIndex}`}
                                        onChange={this.onChange}
                                        value={
                                          this.state.pharmacies[pIndex]
                                            .invoices[iIndex].values.percent
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                                <tr style={{ fontWeight: '600' }}>
                                  <td style={{ padding: '2px' }}></td>
                                  <td style={{ padding: '2px' }}>Total</td>
                                  <td style={{ padding: '2px' }}>
                                    {(p.combinedCreditsTotal || 0).toFixed(2)}
                                  </td>
                                  <td style={{ padding: '2px' }}>
                                    {(p.total || 0).toFixed(2)}
                                  </td>
                                  <td style={{ padding: '2px' }}>
                                    {p.values.totalAmount}
                                  </td>
                                  <td style={{ padding: '2px' }}></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div id="upload-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h5>Upload Mckesson Credits</h5>
          </div>
          <div className="row">
            <div className="file-field col s12 m4">
              <div>
                <div>
                  <input type="file" id="file" name="file" />
                  <label htmlFor="file">Browse Files</label>
                </div>
                <div className="file-path-wrapper">
                  <input id="file-path" className="file-path" type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12 m4 offset-m4">
              <a
                href="/"
                className="btn-small blue white-text col s12 waves-effect waves-light"
                onClick={this.uploadCredits}
              >
                Upload File
              </a>
            </div>
            <div className="col s12 m4">
              <a
                href="/"
                className="btn-small red white-text col s12 waves-effect waves-light"
                onClick={this.closeUploadModal}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="mfr-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h6>Mfr Names</h6>
          </div>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <th>Reported Mfr Name</th>
                  <th>Internal Name</th>
                </tr>
              </thead>
              <tbody>
                {this.state.mfrNameList &&
                  this.state.mfrNameList.map((mfr, index) => (
                    <tr key={`mfr-${mfr.name}`}>
                      <td>{mfr.name}</td>
                      <td>
                        <AutoComplete
                          possibleValues={this.state.mfrList}
                          index={index}
                          setId={this.setMfrId}
                        />
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td>
                    <a
                      href="/"
                      className="btn-small blue white-text col s12"
                      onClick={this.submitNames}
                    >
                      Submit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="unmatched-credits-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h5>Unmatched Credits</h5>
          </div>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <th>Reported Mfr</th>
                  <th>Reported DM #</th>
                  <th>Full Credit Amt</th>
                  <th>Available Credit Amt</th>
                  <th>Debit Memo ID</th>
                </tr>
              </thead>
              <tbody>
                {this.state.unmatchedCredits?.map((c, index) => (
                  <tr key={`cm-${JSON.stringify(c)}`}>
                    <td>{c.reportedManufacturerName}</td>
                    <td>{c.reportedDebitMemoNumber}</td>
                    <td>{c.fullCreditAmount}</td>
                    <td>{c.availableCreditAmount}</td>
                    <td>
                      <input
                        id={`unmatched-input-${index}`}
                        type="number"
                        onChange={this.unmatchedInputChange}
                        value={this.state.unmatchedCredits[index].debitMemoId}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <a
                      href="/"
                      className="btn-small blue white-text"
                      onClick={this.updateUnmatchedCredits}
                    >
                      Match Credits
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(McKesson);

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    value: '',
    suggestion: null,
    selectedText: '',
  });

  componentDidMount = () => {
    document
      .querySelector(`#auto-${this.props.index}`)
      .addEventListener('keyup', this.nextListener);
  };

  componentWillUnmount = () => {
    document
      .querySelector(`#auto-${this.props.index}`)
      .removeEventListener('keyup', this.nextListener);
  };

  nextListener = (e) => {
    if ((e.keyCode === 9 || e.keyCode === 13) && this.state.suggestion) {
      this.props.setId(this.props.index, this.state.suggestion.id);
      this.setState({
        suggestion: null,
        selectedText: '',
        value: this.state.suggestion.name,
      });
    }
  };

  onChange = (e) => {
    e.persist();
    let { value } = e.target;

    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      value = value.substring(0, value.length - 1);
    }

    if (!value)
      return this.setState({ value, suggestion: null, selectedText: '' });

    const suggestion = this.props.possibleValues.filter((pv) =>
      pv.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    )[0];
    let selectedText = suggestion ? suggestion.name : '';

    selectedText = selectedText.split('');
    selectedText.splice(0, value.length);
    selectedText = selectedText.join('');

    this.setState(
      { value: `${value}${selectedText}`, suggestion, selectedText },
      () => {
        document
          .querySelector(`#auto-${this.props.index}`)
          .setSelectionRange(value.length, value.length + selectedText.length);
      }
    );
  };

  render = () => (
    <input
      id={`auto-${this.props.index}`}
      value={this.state.value}
      onChange={this.onChange}
    />
  );
}
