import M from '@materializecss/materialize';
import Axios from 'axios';
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { logOut, registerRoutes } from '../../../func';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const EditInvoiceDetailModal = ({
  invoiceDetail,
  isOpen,
  setIsOpen,
  codes,
  refetch,
}) => {
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const prefix = useMemo(() => 'invoice-detail-modal-', []);
  const authData = useMemo(
    () => JSON.parse(localStorage.getItem('auth_data')),
    []
  );

  const initValues = () => ({ ...invoiceDetail });
  const initSelect = () =>
    M.FormSelect.init(document.querySelector(`#${prefix}code`));

  const [values, setValues] = useState(initValues());
  const [updating, setUpdating] = useState(false);

  useLayoutEffect(() => {
    M.Modal.init(modal.current, {
      onCloseEnd: () => {
        // this doesn't work without this console.log. Don't fight it.. just let it happen.
        console.log();
        setIsOpen(false);
      },
    });

    return () => {
      M.Modal.getInstance(modal.current)?.destroy();
      M.FormSelect.getInstance(
        document.querySelector(`#${prefix}code`)
      )?.destroy();
    };
  }, []);

  const getModal = () => M.Modal.getInstance(modal.current);

  const networkCalls = useMemo(
    () =>
      registerRoutes(
        {
          update: {
            func: async () => {
              try {
                setUpdating(true);
                const result = await Axios.put(
                  '/api/v1/invoice/update/invoicedetail',
                  {
                    ...values,
                    invoiceDetailId: values.id,
                    ...authData,
                  }
                );
                refetch();
                dispatch(result.data);
                getModal()?.close();
              } catch (err) {
                logOut(err);
              } finally {
                setUpdating(false);
              }
            },
            type: 'u',
          },
        },
        pathname
      ),
    [values, logOut, dispatch, setUpdating, refetch, getModal]
  );

  useEffect(() => {
    if (isOpen) {
      getModal()?.open();
      setValues(initValues());
      setTimeout(() => {
        initSelect();
        M.updateTextFields();
      }, 250);
    } else getModal()?.close();
  }, [isOpen]);

  const onChange = ({ target: { id, value } }) => {
    const parsedId = id.replace(prefix, '');
    setValues((prev) => ({ ...prev, [parsedId]: value }));
  };

  const updateInvoiceDetail = (event) => {
    event?.preventDefault();
    networkCalls.update();
  };

  const cancel = (event) => {
    event?.preventDefault();
    getModal()?.close();
  };

  useEffect(() => {
    const { rate, quantity } = values;
    const multiplied = (rate || 0) * (quantity || 0);
    const normalized = Math.round((multiplied + Number.EPSILON) * 100) / 100;
    setValues((prev) => ({ ...prev, total: normalized }));
  }, [values.rate, values.quantity]);

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <h5 className="center">Edit Invoice Detail</h5>
      </div>
      <div className="row">
        <div className="input-field col s12 m4">
          <select id={`${prefix}code`} value={values.code} onChange={onChange}>
            {codes?.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
          <label htmlFor={`${prefix}code`}>Code</label>
        </div>
        <div className="input-field col s12 m8">
          <input
            id={`${prefix}description`}
            type="text"
            value={values.description}
            onChange={onChange}
          />
          <label htmlFor={`${prefix}description`}>Description</label>
        </div>
      </div>
      <div className="row">
        <div className="input-field col s4 m2">
          <input
            id={`${prefix}rate`}
            type="number"
            value={values.rate}
            onChange={onChange}
          />
          <label htmlFor={`${prefix}rate`}>Rate</label>
        </div>
        <div className="input-field col s4 m2">
          <input
            id={`${prefix}quantity`}
            type="number"
            value={values.quantity}
            onChange={onChange}
          />
          <label htmlFor={`${prefix}quantity`}>Qty</label>
        </div>
        <div className="input-field col s4 m2">
          <p> = {values.total}</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4 offset-m4">
          <a
            href="/"
            className={`btn-small blue white-text waves-effect waves-light col s12 ${
              updating ? 'disabled' : ''
            }`}
            onClick={updateInvoiceDetail}
          >
            Update Line Item
          </a>
        </div>
        <div className="col s12 m4">
          <a
            href="/"
            className="btn-small red white-text waves-effect waves-light col s12"
            onClick={cancel}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

export default EditInvoiceDetailModal;
