import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import { logOut, openSideNav, registerRoutes } from "../../func";
import M from "@materializecss/materialize";
import moment from "moment";
import EditInvoiceDetailModal from "./modals/EditInvoiceDetailModal";

class UpdateInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem("auth_data"));
    this.employeeData = JSON.parse(localStorage.getItem("employee_data"));
    this.invoiceId = this.props.match.params.id;
    this.state = this.initState();
  }

  initState = () => ({
    invoice: null,
    invoiceDetails: null,
    pharmacy: null,
    createdBy: null,
    contextX: -500,
    contextY: -500,
    grandTotal: 0,
    discounts: 0,
    payments: null,
    values: this.initValues(),
    payment: this.initPayment(),
    hoverIndex: -1,
    isOpenEditInvoiceDetailModal: false,
    selectedInvoiceDetail: null,
  });

  initValues = () => ({
    code: "",
    description: "",
    quantity: "",
    rate: "",
  });

  initPayment = () => ({
    amount: "",
    method: "",
    transactionNumber: "",
    notes: "",
    transactionDate: moment().format("YYYY-MM-DD"),
  });

  valueChange = (e) =>
    this.setState((p) => ({
      values: { ...p.values, [e.target.id]: e.target.value },
    }));
  paymentChange = (e) =>
    this.setState((p) => ({
      payment: { ...p.payment, [e.target.id]: e.target.value },
    }));

  componentDidMount = () => {
    this.networkCalls = registerRoutes(
      this.networkCalls,
      this.props.match.path
    );
    this.getInvoice();
    M.Modal.init(document.querySelectorAll(".modal"));
    M.FormSelect.init(document.querySelectorAll("select"));
    document.addEventListener("click", this.clickAwayListener);
  };

  networkCalls = {
    getInvoice: {
      func: () => {
        this.setState({ loading: true }, () => {
          Axios.get("/api/v1/invoice/read/one/active/by/id", {
            params: {
              ...this.authData,
              id: this.invoiceId,
            },
          })
            .then((result) =>
              this.setState({ ...result.data }, this.calcMinIsMet)
            )
            .catch(logOut)
            .finally(() => this.setState({ loading: false }));
        });
      },
      type: "r",
    },
    addLine: {
      func: () => {
        Axios.post("/api/v1/invoice/create/lineitem", {
          ...this.authData,
          ...this.state.values,
          invoiceId: this.state.invoice.id,
          total: parseFloat(
            this.state.values.rate * this.state.values.quantity
          ).toFixed(2),
        })
          .then((result) => {
            this.getInvoice();
            this.props.dispatch(result.data);
            this.closeModal();
          })
          .catch(logOut);
      },
      type: "c",
    },
    deleteLineItem: {
      func: () => {
        Axios.post("/api/v1/invoice/delete/lineitem", {
          ...this.authData,
          id: this.state.selectedId,
        })
          .then((result) => {
            this.props.dispatch(result.data);
            this.setState(
              { selectedId: -1, contextX: -500, contextY: -500 },
              this.getInvoice
            );
          })
          .catch(logOut);
      },
      type: "d",
    },
    deleteInvoice: {
      func: () => {
        Axios.delete("/api/v1/invoice/delete/one/by/id", {
          params: {
            ...this.authData,
            invoiceId: this.state.invoice.id,
          },
        })
          .then((result) => {
            this.props.dispatch(result.data);
            this.props.history.push("/invoice");
          })
          .catch(logOut);
      },
      type: "d",
    },
    print: {
      func: () => {
        Axios.get("/api/v1/invoice/print/invoice", {
          params: {
            ...this.authData,
            invoiceId: this.state.invoice.id,
          },
          responseType: "blob",
        })
          .then((result) => {
            const blob = new Blob([result.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.hidden = true;
            link.href = url;
            link.setAttribute(
              "download",
              `invoice_${this.state.invoice.id.toString().padStart(4, "0")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(logOut);
      },
      type: "r",
    },
    savePayment: {
      func: () => {
        Axios.post("/api/v1/invoice/update/makepayment", {
          ...this.authData,
          ...this.state.payment,
          invoiceId: this.state.invoice.id,
          recordedById: this.employeeData.id,
        })
          .then((result) => {
            this.props.dispatch(result.data);
            this.getInvoice();
            this.closePaymentModal();
          })
          .catch(logOut);
      },
      type: "u",
    },
    closeInvoice: {
      func: () => {
        Axios.post("/api/v1/invoice/update/closeinvoice", {
          ...this.authData,
          id: this.state.invoice.id,
          closedById: this.employeeData.id,
        })
          .then((result) => this.props.dispatch(result.data))
          .catch(logOut);
      },
      type: "u",
    },
  };

  clickAwayListener = ({ target }) => {
    if (!document.querySelector(`#context-menu`)?.contains(target)) {
      this.setState({
        contextX: -500,
        contextY: -500,
        selectedId: -1,
      });
    }
  };

  getInvoice = () => this.networkCalls.getInvoice();
  addLine = (e) => {
    e?.preventDefault();
    this.networkCalls.addLine();
  };
  deleteLineItem = (e, id) => {
    e?.preventDefault();
    if (window.confirm("Are you sure you want to delete this line item?"))
      this.networkCalls.deleteLineItem();
    else {
      this.setState({
        selectedId: -1,
        contextX: -500,
        contextY: -500,
      });
    }
  };
  print = (e) => {
    e?.preventDefault();
    this.networkCalls.print();
  };
  savePayment = (e) => {
    e?.preventDefault();
    this.networkCalls.savePayment();
  };
  closeInvoice = (e) => {
    e?.preventDefault();
    this.networkCalls.closeInvoice();
  };

  calcMinIsMet = () => {
    let paid = 0;
    if (this.state.payments)
      this.state.payments.forEach((p) => (paid += parseFloat(p.amount)));

    const minFee = this.state.invoiceDetails.filter(
      (id) => id.code === "Minimum Service Fee"
    );
    let sum = 0.0;
    let min = minFee.length ? parseFloat(minFee[0].total) : 0;
    let discounts = 0;

    this.state.invoiceDetails.forEach((id) => {
      if (id.total > 0) sum += id.code === "Minimum Service Fee" ? 0 : id.total;
    });
    this.state.invoiceDetails.forEach(
      (id) => (discounts += id.rate < 0 ? id.rate * id.quantity : 0)
    );

    const minMet = sum > min;
    const grandTotal = `Total: ${parseFloat(minMet ? sum : min).toFixed(2)}`;
    const totalPaid = `Paid: -${parseFloat(paid).toFixed(2)}`;
    const remainingBalance = `Balance $${(
      (minMet ? sum : min) -
      paid +
      discounts
    ).toFixed(2)}`;

    this.setState({
      minMet,
      grandTotal,
      totalPaid,
      remainingBalance,
      paid: (minMet ? sum : min) <= paid,
      discounts,
    });
  };

  openModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#add-line-modal")).open();
  };

  closeModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#add-line-modal")).close();
    this.setState({ values: this.initValues() }, () => {
      M.FormSelect.init(document.querySelectorAll("select"));
      M.updateTextFields();
    });
  };

  openPaymentModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#payment-modal")).open();
  };

  closePaymentModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#payment-modal")).close();
    this.setState({ payment: this.initPayment() }, () => {
      M.FormSelect.init(document.querySelectorAll("select"));
      M.updateTextFields();
      M.textareaAutoResize(document.querySelector("#notes"));
    });
  };

  getCodes = () => [
    "Basic Service Fee",
    "Minimum Service Fee",
    "Regular Destruction",
    "RCRA Destruction",
    "Shipping",
    "Other",
  ];

  contextMenu = (e, id) => {
    e.preventDefault();
    if (id.dateExportedToQuickbooks)
      return this.props.dispatch({
        type: "GLOBAL_TOAST",
        payload: { msg: "Line Item Locked", class: "orange white-text" },
      });

    this.setState({
      selectedId: id.id,
      showContextMenu: true,
      contextX: e.clientX + 20,
      contextY: e.clientY,
    });
  };

  getPaymentMethods = () => ["Cash", "Credit Card", "Check"];

  openAttachmentModal = (e) => {
    e?.preventDefault();
    M.Modal.getInstance(document.querySelector("#attachment-modal")).open();
  };

  editLineItem = (e) => {
    e?.preventDefault();
    const selectedInvoiceDetail = this.state.invoiceDetails.find(
      (id) => id.id === this.state.selectedId
    );
    this.setState({
      selectedInvoiceDetail,
      isOpenEditInvoiceDetailModal: true,
    });
  };

  deleteInvoice = (e) => {
    e?.preventDefault();
    if (window.confirm("Are you sure you would like to delete this invoice?"))
      this.networkCalls.deleteInvoice();
  };

  render = () => {
    return (
      <div className="main">
        <div className="row">
          <div style={{ display: "flex" }}>
            <Link to="/" onClick={openSideNav} style={{ marginRight: "12px" }}>
              <i className="material-icons black-text">menu</i>
            </Link>
            <Link to="/">Home</Link>
            <i className="material-icons">chevron_right</i>
            <Link to="/accounting">Accounting</Link>
            <i className="material-icons">chevron_right</i>
            <Link to="/invoice">Invoicing</Link>
            <i className="material-icons">chevron_right</i>
            <span className="grey-text">
              Update {this.invoiceId?.toString().padStart(4, "0")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div className="card" style={{ fontSize: "1.15em" }}>
              <div className="card-content">
                <div className="row">
                  <div className="col s6">
                    <img
                      src="/img/orx_pill_no_background.png"
                      alt="orx_pill"
                      className="responsive-img"
                    />
                  </div>
                  <div className="col s6">
                    <h2 className="right" style={{ margin: "0px" }}>
                      Invoice
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col s6">
                    <p>1125 Research Dr</p>
                    <p>Suite A</p>
                    <p>Redlands, CA 92374</p>
                  </div>
                  <div className="col s6">
                    <div className="col s3 offset-s6">
                      <p style={{ textAlign: "right", fontWeight: "700" }}>
                        Service Date
                      </p>
                      <p style={{ textAlign: "right", fontWeight: "700" }}>
                        Order Number
                      </p>
                      <p style={{ textAlign: "right", fontWeight: "700" }}>
                        Invoice #
                      </p>
                      <p style={{ textAlign: "right", fontWeight: "700" }}>
                        Due Date
                      </p>
                    </div>
                    <div className="col s3">
                      <p>
                        {this.state.invoice &&
                          moment(this.state.orderHeader.orderDate).format(
                            "YYYY-MM-DD"
                          )}
                      </p>
                      <p>
                        {this.state.invoice &&
                          this.state.orderHeader.orderNumber}
                      </p>
                      <p>
                        {this.state.invoice &&
                          this.state.invoice.id.toString().padStart(4, "0")}
                      </p>
                      <p>
                        {this.state.invoice &&
                          moment(this.state.invoice.dateCreated).format(
                            "YYYY-MM-DD"
                          )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s5">
                    <h6
                      className="grey lighten-2"
                      style={{
                        fontWeight: "600",
                        padding: "4px",
                        paddingLeft: "8px",
                      }}
                    >
                      BILL TO
                    </h6>
                    {this.state.pharmacy && (
                      <div>
                        <p>{this.state.pharmacy.name}</p>
                        <p>{this.state.pharmacy.addressLineOne}</p>
                        <p>{this.state.pharmacy.addressLineTwo}</p>
                        <p>
                          {this.state.pharmacy.city},{" "}
                          {this.state.pharmacy.state} {this.state.pharmacy.zip}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <table
                      className="striped"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <thead className="grey lighten-2">
                        <tr style={{ borderBottom: "1px solid black" }}>
                          <th
                            style={{
                              padding: "1px",
                              borderRadius: "0px",
                              paddingLeft: "3px",
                            }}
                          >
                            Code
                          </th>
                          <th style={{ padding: "1px", borderRadius: "0px" }}>
                            Description
                          </th>
                          <th style={{ padding: "1px", borderRadius: "0px" }}>
                            Rate
                          </th>
                          <th style={{ padding: "1px", borderRadius: "0px" }}>
                            Quantity
                          </th>
                          <th
                            style={{
                              padding: "1px",
                              borderRadius: "0px",
                              textAlign: "right",
                              paddingRight: "3px",
                            }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.invoiceDetails &&
                          this.state.invoiceDetails.map((id, index) => {
                            if (id.code === "Minimum Service Fee")
                              return (
                                <tr
                                  key={`invoice-detail-${id.id}`}
                                  className={
                                    this.state.selectedId === id.id
                                      ? "orange white-text"
                                      : ""
                                  }
                                  onContextMenu={(e) => this.contextMenu(e, id)}
                                >
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {id.dateExportedToQuickbooks && (
                                        <i className="material-icons">
                                          lock_outline
                                        </i>
                                      )}
                                      <span
                                        style={{
                                          textDecoration: this.state.minMet
                                            ? "line-through"
                                            : "",
                                        }}
                                      >
                                        {id.code}
                                      </span>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? "line-through"
                                        : "",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.description}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? "line-through"
                                        : "",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.rate}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? "line-through"
                                        : "",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? "line-through"
                                        : "",
                                      padding: "1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    ${id.total?.toFixed(2)}
                                  </td>
                                </tr>
                              );
                            else if (id.rate < 0)
                              return (
                                <tr
                                  key={`invoice-detail-${id.id}`}
                                  className={
                                    this.state.selectedId === id.id
                                      ? "orange white-text"
                                      : ""
                                  }
                                  onContextMenu={(e) => this.contextMenu(e, id)}
                                >
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                      }}
                                    >
                                      {id.dateExportedToQuickbooks && (
                                        <i className="material-icons">
                                          lock_outline
                                        </i>
                                      )}
                                      <span>{id.code}</span>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.description}
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.rate}
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.quantity}
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    ${id.total?.toFixed(2)}
                                  </td>
                                </tr>
                              );
                            else
                              return (
                                <tr
                                  key={`invoice-detail-${id.id}`}
                                  className={
                                    this.state.selectedId === id.id
                                      ? "orange white-text"
                                      : ""
                                  }
                                  onContextMenu={(e) => this.contextMenu(e, id)}
                                >
                                  <td
                                    style={{
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                      }}
                                    >
                                      {id.dateExportedToQuickbooks && (
                                        <i className="material-icons">
                                          lock_outline
                                        </i>
                                      )}
                                      <span
                                        style={{
                                          textDecoration: this.state.minMet
                                            ? ""
                                            : "line-through",
                                        }}
                                      >
                                        {id.code}
                                      </span>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? ""
                                        : "line-through",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.description}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? ""
                                        : "line-through",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.rate}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? ""
                                        : "line-through",
                                      padding: "1px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {id.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textDecoration: this.state.minMet
                                        ? ""
                                        : "line-through",
                                      padding: "1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    ${id.total?.toFixed(2)}
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </table>
                    <div className="col s4 offset-s8">
                      <p className="right">{this.state.grandTotal}</p>
                    </div>
                    <div className="col s4 offset-s8">
                      <p className="right">
                        {this.state.discounts &&
                          `Discounts: ${this.state.discounts.toFixed(2)}`}
                      </p>
                    </div>
                    <div className="col s4 offset-s8">
                      <p className="right">{this.state.totalPaid}</p>
                    </div>
                    <div className="col s4 offset-s8">
                      <b className="right">{this.state.remainingBalance}</b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col s8"
                    style={{
                      border: "1px solid black",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <h6
                      className="grey lighten-2"
                      style={{
                        fontWeight: "600",
                        padding: "4px",
                        paddingLeft: "8px",
                        margin: "0px",
                      }}
                    >
                      COMMENTS
                    </h6>
                    <div style={{ padding: "8px" }}>
                      <p>
                        {this.state.invoice &&
                          this.state.invoice.noteToCustomer}
                      </p>
                      <p>Thank you for your business!</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "80px" }}>
                  <div className="col s12 m2 offset-m8">
                    <a
                      href="/"
                      className="btn-small blue white-text waves-effect waves-light col s12"
                      onClick={this.openModal}
                    >
                      Add Line Item
                    </a>
                  </div>
                  <div className="col s12 m2">
                    <a
                      href="/"
                      className="btn-small cyan white-text waves-effect waves-light col s12"
                      onClick={this.print}
                    >
                      Print
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="add-line-modal" className="modal">
          <div className="modal-content">
            <h5 className="center">Add Line</h5>
            <div className="row">
              <div className="input-field col s12 m4">
                <select
                  id="code"
                  onChange={this.valueChange}
                  value={this.state.values.code}
                >
                  <option value={""}></option>
                  {this.getCodes().map((c) => (
                    <option key={`code-${c}`} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
                <label htmlFor="code">Code</label>
              </div>
              <div className="input-field col s12 m8">
                <input
                  id="description"
                  type="text"
                  onChange={this.valueChange}
                  value={this.state.values.description}
                />
                <label htmlFor="description">Description</label>
              </div>
              <div className="input-field col s12 m2">
                <input
                  id="rate"
                  type="number"
                  onChange={this.valueChange}
                  value={this.state.values.rate}
                />
                <label htmlFor="rate">Rate</label>
              </div>
              <div className="input-field col s12 m2">
                <input
                  id="quantity"
                  type="number"
                  onChange={this.valueChange}
                  value={this.state.values.quantity}
                />
                <label htmlFor="quantity">Qty</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 m3 offset-m6">
                <a
                  href="/"
                  className="btn-small blue white-text col s12 waves-effect waves-light"
                  onClick={this.addLine}
                >
                  Add Line Item
                </a>
              </div>
              <div className="input-field col s12 m3">
                <a
                  href="/"
                  className="btn-small red white-text col s12 waves-effect waves-light"
                  onClick={this.closeModal}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="payment-modal" className="modal">
          <div className="modal-content">
            <div className="row">
              <h6 className="center">Payment</h6>
            </div>
            <div className="row">
              <div className="input-field col s2">
                <input
                  id="amount"
                  type="number"
                  onChange={this.paymentChange}
                  value={this.state.payment.amount}
                />
                <label htmlFor="amount">Payment Amount</label>
              </div>
              <div className="input-field col s3">
                <select
                  id="method"
                  onChange={this.paymentChange}
                  value={this.state.payment.method}
                >
                  <option value={""}>Select Payment Method</option>
                  {this.getPaymentMethods().map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field col s4">
                <input
                  id="transactionNumber"
                  type="text"
                  onChange={this.paymentChange}
                  value={this.state.payment.transactionNumber}
                />
                <label htmlFor="transactionNumber">Transaction Number</label>
              </div>
              <div className="input-field col s3">
                <input
                  id="transactionDate"
                  type="date"
                  onChange={this.paymentChange}
                  value={this.state.payment.transactionDate}
                />
                <label htmlFor="transactionDate">Transaction Date</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s8">
                <textarea
                  id="notes"
                  className="materialize-textarea"
                  onChange={this.paymentChange}
                  value={this.state.payment.notes}
                />
                <label htmlFor="notes">Notes</label>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m3 right">
                <a
                  href="/"
                  className="btn-small red white-text col s12 waves-effect waves-light"
                  onClick={this.closePaymentModal}
                >
                  Cancel
                </a>
              </div>
              <div className="col s12 m3 right">
                <a
                  href="/"
                  className="btn-small blue white-text col s12 waves-effect waves-light"
                  onClick={this.savePayment}
                >
                  Save Payment
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="attachment-modal" className="modal">
          <div className="modal-content">
            <div className="row">
              <h5 className="center">Attachments</h5>
            </div>
            <div className="row">
              {this.state.attachments?.map((a) => (
                <p>{a.filename}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4 l2">
            <a
              href="/"
              className="btn-small green white-text waves-effect waves-light col s12"
              onClick={this.openPaymentModal}
            >
              Add Payment
            </a>
          </div>
          <div className="col s12 m4 l2">
            <a
              href="/"
              className="btn-small cyan white-text waves-effect waves-light col s12"
              onClick={this.openAttachmentModal}
            >
              Attachments
            </a>
          </div>
          <div className="col s12 m4 l2">
            <a
              href="/"
              className="btn-small red white-text waves-effect waves-light col s12"
              onClick={this.closeInvoice}
            >
              Close Invoice
            </a>
          </div>
          {this.state.invoiceDetails?.length === 1 &&
            !this.state.invoiceDetails[0].id &&
            !this.state.payments?.length && (
              <div className="col s12 m4 l2">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={this.deleteInvoice}
                >
                  Delete Invoice
                </a>
              </div>
            )}
        </div>
        <div
          id="context-menu"
          style={{
            zIndex: 101,
            position: "fixed",
            left: this.state.contextX,
            top: this.state.contextY,
            border: "1px solid black",
            backgroundColor: "white",
            boxShadow: "1px 1px 2px #ddd",
            borderRadius: "1px",
            padding: "4px 12px",
            width: "210px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "12px",
          }}
        >
          <a
            href="/"
            className="black-text"
            style={{
              font: "console",
              fontSize: ".8em",
              wordWrap: "break-word",
            }}
            onClick={this.deleteLineItem}
          >
            Delete Line Item
          </a>
          <a
            href="/"
            className="black-text"
            style={{
              font: "console",
              fontSize: ".8em",
              wordWrap: "break-word",
            }}
            onClick={this.editLineItem}
          >
            Edit Line Item
          </a>
        </div>
        {this.state.paid && (
          <p
            style={{
              position: "fixed",
              color: "rgb(229, 28, 35, .25)",
              top: "10%",
              left: "40%",
              transform: "rotate(-30deg)",
              fontSize: "16em",
              fontWeight: "700",
            }}
          >
            PAID
          </p>
        )}
        <EditInvoiceDetailModal
          isOpen={this.state.isOpenEditInvoiceDetailModal}
          setIsOpen={(bool) =>
            this.setState({ isOpenEditInvoiceDetailModal: Boolean(bool) })
          }
          invoiceDetail={this.state.selectedInvoiceDetail}
          codes={this.getCodes()}
          refetch={this.networkCalls.getInvoice}
        />
      </div>
    );
  };
}

export default connect()(UpdateInvoice);
